import { BsChevronRight } from "react-icons/bs";
import blog from "../../assets/blog-1.jpg";
import IMG1 from "../../assets/blog-1.jpg";
import IMG2 from "../../assets/team-1.jpg";

function BusinessGrowLearnMore({ ref }) {
  return (
    <div className="container mt-5 pt-5">
      <h2 className="text-center mb-4 section-header mt-5">
        Access Erp on Cloud What is Happening the Factory, Whenever You Are In
        The World
      </h2>
      <div className="row mt-5">
        <div className="col-12 col-md-5 ">
          <img src={IMG1} />
        </div>

        <div className="col-12 col-md-7 h-100">
          <h5 className="text-center text-md-center mt-4 mt-md-0 text-type-2 ">
            Access Infotech provides professional <span>Cloud Services</span>{" "}
            and implementation support for the Cloud.
            <br />
            <span>With complete integrated Access Cloud solutions,</span> drives
            nonstop improvements across your organization. Reduce your
            infrastructure capital requirements and access skill improves
            security <span>& compliance & drive growth.</span>
            <br />
            Now days, <span>
              fast-growing companies have started adopting
            </span>{" "}
            cloud based ERP solutions very rapidly. Small or large, every
            business seeks to minimize costs.
            <br />
            More rapidly and efficiently you control your IT costs, improve
            collaboration, drive <span>innovation</span> and information
            exchange, the more you will distinguish yourself from competitors,
            and the sooner you will reach your business goals.
          </h5>
        </div>
      </div>

      <h2 className="text-center mb-4 section-header mt-5 pt-5">
        Access Erp Solution On Cloud Raise Your Business Production
      </h2>

      <div className="row mt-5 pt-5 gy-5 gy-md-0">
        <div className="col-12 col-md-4 ">
          <div className="service-card position-relative card pt-5 pb-4 px-0">
            <div className="position-absolute avatar-circle-container">
              <div className="avatar-circle">
                <img src={IMG2} />
              </div>
            </div>

            <h4 className="service-card-title px-3">
              Increase Mobility & Stay Up to Date
            </h4>

            <p className="px-3 text-type-2  text-center service-card-description">
              Run your critical business data{" "}
              <span>anywhere, at any time,</span> on any device and maintain
              business continuty. Either at a meeting, field or site. You can
              enter data using your{" "}
              <span>mobile device, eliminating the need</span> to produce
              unnecessery paper work.
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4 ">
          <div className="service-card position-relative card pt-5 pb-4 px-0">
            <div className="position-absolute avatar-circle-container">
              <div className="avatar-circle">
                <img src={IMG2} />
              </div>
            </div>

            <h4 className="service-card-title px-3">
              Increase Mobility & Stay Up to Date
            </h4>

            <p className="px-3 text-type-2  text-center service-card-description">
              Maintain communication and benefits from{" "}
              <span>greater collaboration across locations.</span>Team members
              can enter data while in the field and communicate with supplier in
              a flash user can check the{" "}
              <span>status of an order whenever it suits them.</span>
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4 ">
          <div className="service-card position-relative card pt-5 pb-4 px-0">
            <div className="position-absolute avatar-circle-container">
              <div className="avatar-circle">
                <img src={IMG2} />
              </div>
            </div>

            <h4 className="service-card-title px-3">
              Increase Mobility & Stay Up to Date
            </h4>

            <p className="px-3 text-type-2  text-center service-card-description">
              Concentrate on growing your business with less{" "}
              <span>attention to IT hardware and software requirements.</span>{" "}
              Focus your corporate resources <span>"your core business"</span>{" "}
              rather than running adminstrating IT infrastructure.
            </p>
          </div>
        </div>
      </div>

      {/* jjjjjjjjjjjjjjj */}
      <h2 className="text-center mb-4 section-header mt-5 pt-5">
        Startegic Partner to Your Business
      </h2>

      <div className="row">
        <div className="col-12 card oracle-card p-4">
          <p className="oracle-card-title">
            ORACLE/MS-SQL/MYSQL Affordable Database Administrator Services
          </p>
          <div className="d-flex justify-content-start align-items-center">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">Remote / On Site DBA</p>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">Services</p>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">
              Flexible Scheduling, Fixed Cost
            </p>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">
              Experienced Certified Database Professionals
            </p>
          </div>
        </div>

        <div className="col-12 card oracle-card p-4 mt-5">
          <p className="oracle-card-title">
            Access DBA Support Services Results In Low Cost And Improved
            Database Infrastructure, Protecting your Organisation's
            Mission-Critical Data
          </p>
          <div className="d-flex justify-content-start align-items-center">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">
              Offering your business a wide range of database adminstration
              services.
            </p>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">
              Reduced cost on the maintenance and support for database and
              system environments.
            </p>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">
              Proactive database maintenance by certified DBAs with constant
              database monitoring & alert notifications.
            </p>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3">
            <BsChevronRight color="white" size={18} />
            <p className="text-type-1 oracle-card-list">
              Comprehensive monthly reviews & reports.
            </p>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <div className="card oracle-card p-4 h-100">
            <p className="oracle-card-title">DBA Support Services</p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Daily Proactive monitoring.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Production Support & Troubleshooting.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Upgrades/Installations/ Migrations
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Oracle apps 11i / R12 support.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Support for Cloning/Patching.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                User & Security adminstration.
              </p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <p className="oracle-card-title">DBA One time Solutions</p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Database Setup & Configuration.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">Oracle RAC Setup.</p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Database Migration Platform/Hardware.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Disaster Recovery Solutions & Configuration.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Performance Turing & SQL Analysis.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Removing Object De-frafmentation & Space Management.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* hhhhhhhhhhhhhhhh */}

      <h2 className="text-center mb-4 section-header mt-5 pt-5">
        Database Services
      </h2>

      <div className="row mt-5 gy-4 row-col-md-1">
        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <div className="avatar-circle mx-auto mb-3">
              <img src={IMG2} />
            </div>
            <p className="oracle-card-title">Database Architecture</p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Organization Database Requirements Policies & Standards.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Install/Restore Database.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Upgrade/Applications Environment & Secure Code Control.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Physical Database Storage Structures.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Errors, Alerts & Testing Procedures.
              </p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <div className="avatar-circle mx-auto mb-3">
              <img src={IMG2} />
            </div>
            <p className="oracle-card-title">
              Database Administration & Support
            </p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Review Error/Alerts Logs And Provide Fixes.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Forecast Space Requirements And Monitoring Database Growth.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Performance Monitoring And Optimization.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Database/Middleware/Oracle Apps Installations.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Monitoring mutiple database/application server/RAC database
                custom tool/Enterprise manager/grid management.
              </p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <div className="avatar-circle mx-auto mb-3">
              <img src={IMG2} />
            </div>
            <p className="oracle-card-title">Database Maintenance</p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Adjustment & Configuration Management.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                New User Enrollment & System Security Maintenance.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Test Conduct Database Migrations.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Database-Management Software Adminstration.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Oracle License Agreements.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Maintaning Data Integrity Post-Migration.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Troubleshooting & Modifications Of Database.
              </p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <div className="avatar-circle mx-auto mb-3">
              <img src={IMG2} />
            </div>
            <p className="oracle-card-title">
              Database Monitoring & Resolution
            </p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Monitoring Error Log/Event.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Log Database Problem Reporting & Resolution.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Risk & Impact Analysis Of Maintenance & New Releases.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                User & Developer Account Audits
              </p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <div className="avatar-circle mx-auto mb-3">
              <img src={IMG2} />
            </div>
            <p className="oracle-card-title">Backup & Recovery</p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Define & Configure Backup Plans As Per Business Needs.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Schedule Hot And Cold Backups.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Perform Export Backups And Import With/Without Bata Pumps.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Database Restoration In Case Of Failure.
              </p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="col-12 card oracle-card p-4 h-100">
            <div className="avatar-circle mx-auto mb-3">
              <img src={IMG2} />
            </div>
            <p className="oracle-card-title">Data Mining & Data Warehousing</p>
            <div className="d-flex justify-content-start align-items-center">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Database warehousing for data extraction, reporting & analysis.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Data mining for Targeted Analysis & Business Intelligence.
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
              <BsChevronRight color="white" size={18} />
              <p className="text-type-1 oracle-card-list">
                Archiving, Reporting, Retreiving & Creating Data Interfaces.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessGrowLearnMore;
